import { create } from 'zustand'
import { persist, createJSONStorage, StateStorage } from 'zustand/middleware'
import { getFromSS, removeFromSS, setInSS } from '@flash-tecnologia/hros-web-utility'

export interface BenefitsConfigs {
  amount: number
  active: boolean
  nanoId: string
  name: string
}

export interface Employee {
  nanoId: string
  name: string
  cpf: string
  group: string
  benefitsConfigs: BenefitsConfigs[]
}

export interface BenefitOrderTransition {
  benefitType: 'plastic' | 'virtual'
  isTopup: boolean
  selectedCheckoutEmployeesList: Employee[]
  checkoutPreferences?: {
    orderMethod: 'platform' | 'spreadsheet'
    creditType: 'accumulative' | 'topup'
  }
}

export interface BenefitOrderTransitionState {
  setBenefitOrderTransition: ({
    selectedCheckoutEmployeesList,
    benefitType,
    isTopup,
  }: BenefitOrderTransition) => void
  setBenefitOrderCheckoutPreferences: (
    checkoutPreferences: BenefitOrderTransition['checkoutPreferences'],
  ) => void
}

const storage: StateStorage = {
  getItem: (name: string): string | null => {
    return getFromSS(name) || null
  },
  setItem: (name: string, value: string): void => setInSS({ key: name, value }),
  removeItem: (name: string): void => removeFromSS({ key: name }),
}

export const useBenefitOrder = create<BenefitOrderTransitionState & BenefitOrderTransition>()(
  persist(
    (set) => ({
      selectedCheckoutEmployeesList: [],
      benefitType: null,
      isTopup: false,
      checkoutPreferences: null,
      setBenefitOrderTransition: (value) => set({ ...value }),
      setBenefitOrderCheckoutPreferences: (value) => set({ checkoutPreferences: value }),
    }),
    {
      name: 'benefitOrder',
      storage: createJSONStorage(() => storage),
    },
  ),
)
